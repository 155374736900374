.w-md-editor {
  text-align: left;
  border-radius: 3px;
  padding-bottom: 1px;
  position: relative;
  color: var(--color-fg-default);
  --md-editor-font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  --md-editor-background-color: var(--color-canvas-default, #ffffff);
  --md-editor-box-shadow-color: var(--color-border-default, #d0d7de);
  box-shadow: 0 0 0 1px var(--md-editor-box-shadow-color), 0 0 0 var(--md-editor-box-shadow-color), 0 1px 1px var(--md-editor-box-shadow-color);
  background-color: var(--md-editor-background-color);
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  display: flex;
  flex-direction: column;
}
.w-md-editor.w-md-editor-rtl {
  direction: rtl !important;
  text-align: right !important;
}
.w-md-editor.w-md-editor-rtl .w-md-editor-preview {
  right: unset !important;
  left: 0;
  text-align: right !important;
  box-shadow: inset -1px 0 0 0 var(--md-editor-box-shadow-color);
}
.w-md-editor.w-md-editor-rtl .w-md-editor-text {
  text-align: right !important;
}
.w-md-editor-toolbar {
  height: -webkit-fit-content;
  height: fit-content;
}
.w-md-editor-content {
  height: 100%;
  overflow: auto;
  position: relative;
  border-radius: 0 0 3px 0;
}
.w-md-editor .copied {
  display: none !important;
}
.w-md-editor-input {
  width: 50%;
  height: 100%;
}
.w-md-editor-text-pre > code {
  word-break: break-word !important;
  white-space: pre-wrap !important;
}
.w-md-editor-preview {
  width: 50%;
  box-sizing: border-box;
  box-shadow: inset 1px 0 0 0 var(--md-editor-box-shadow-color);
  position: absolute;
  padding: 10px 20px;
  overflow: auto;
  top: 0;
  right: 0;
  bottom: 0;
  border-radius: 0 0 5px 0;
  display: flex;
  flex-direction: column;
}
.w-md-editor-preview .anchor {
  display: none;
}
.w-md-editor-preview .contains-task-list li.task-list-item {
  list-style: none;
}
.w-md-editor-show-preview .w-md-editor-input {
  width: 0%;
  overflow: hidden;
  background-color: var(--md-editor-background-color);
}
.w-md-editor-show-preview .w-md-editor-preview {
  width: 100%;
  box-shadow: inset 0 0 0 0;
}
.w-md-editor-show-edit .w-md-editor-input {
  width: 100%;
}
.w-md-editor-show-edit .w-md-editor-preview {
  width: 0%;
  padding: 0;
}
.w-md-editor-fullscreen {
  overflow: hidden;
  position: fixed;
  z-index: 99999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100% !important;
}
.w-md-editor-fullscreen .w-md-editor-content {
  height: 100%;
}
